<template>
  <div class="digitalHumans">
    <div class="flex-c">
      <img src="@/assets/images/logo.png" width="84" height="29" alt="" />
    </div>
    <div class="flex-c-c mt-27">
      <img src="@/assets/images/digitalHumans/title.jpg" width="260" alt="" />
      <div class="line mt-24"></div>
    </div>
    <div class="video-list mt-26 pl-16 pr-16">
      <div class="item mb-16" v-for="i in videoList" :key="i.id">
        <div
          class="item-header flex-b"
          :class="{
            'borderRadius-9': openKey !== i.id,
          }"
          @click="openKey = openKey === i.id ? '' : i.id"
        >
          <div>{{ i.label }}</div>
          <DownOutlined
            style="color: #fff; font-size: 14px"
            :rotate="openKey === i.id ? 180 : 0"
          />
        </div>
        <div class="content" v-show="openKey === i.id">
          <div class="item-content">
            <video
              class="iss-video"
              :id="i.id"
              controls
              :poster="i.videoImgUrl"
              @play="play(i.id)"
            >
              <source :src="i.url" type="video/mp4" />
            </video>
          </div>
          <div class="item-footer">
            <div class="footer-btn" @click="subscribe(i.id)">
              <CheckCircleOutlined
                class="fs-18"
                v-show="!subscribeList.includes(i.id)"
              />
              <CheckCircleFilled
                class="fs-18"
                v-show="subscribeList.includes(i.id)"
              />
              <span class="pl-10">{{
                !subscribeList.includes(i.id) ? '预约' : '取消预约'
              }}</span>
            </div>
            <div class="footer-btn" @click="collect(i.id)">
              <StarOutlined
                class="fs-18"
                v-show="!collectList.includes(i.id)"
              />
              <StarFilled class="fs-18" v-show="collectList.includes(i.id)" />
              <span class="pl-10">{{
                !collectList.includes(i.id) ? '收藏' : '取消收藏'
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a-modal
    v-model:visible="formModal"
    :footer="null"
    :closable="false"
    :maskClosable="false"
    :centered="true"
    :bodyStyle="{
      padding: '24px 0',
    }"
  >
    <div class="modal-header">
      <div class="fs-16" style="color: #ff6412; text-align: center">
        展台预约
      </div>
      <div class="close" @click="close">
        <CloseCircleOutlined class="fs-27" style="color: #fff" />
      </div>
    </div>
    <div class="modal-content">
      <a-form ref="formRef" :model="form" :rules="rules" layout="vertical">
        <a-form-item label="姓名" name="name">
          <a-input v-model:value="form.name" />
        </a-form-item>
        <a-form-item label="手机" name="phone">
          <a-input v-model:value="form.phone" />
        </a-form-item>
        <a-form-item label="邮箱" name="email">
          <a-input v-model:value="form.email" />
        </a-form-item>
        <a-form-item label="公司" name="company">
          <a-input v-model:value="form.company" />
        </a-form-item>
        <a-form-item label="职位" name="position">
          <a-input v-model:value="form.position" />
        </a-form-item>
        <a-form-item label="日期" name="date">
          <a-date-picker
            v-model:value="form.date"
            show-time
            type="date"
            style="width: 100%"
          />
        </a-form-item>
      </a-form>
    </div>
    <div class="modal-footer pl-24 pr-24">
      <div class="footer-btn" style="width: 100%" @click="handleOk">提交</div>
    </div>
  </a-modal>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from 'vue';
import {
  DownOutlined,
  CheckCircleOutlined,
  StarOutlined,
  StarFilled,
  CheckCircleFilled,
  CloseCircleOutlined,
} from '@ant-design/icons-vue';
import { DatePicker, Form, message } from 'ant-design-vue';
import { mobileRegExp } from '@/utils/validation';
import wx from 'weixin-js-sdk';
import wechatApi from '@/api/wechatServer';

export default {
  name: 'digitalHumans',
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    ADatePicker: DatePicker,
    DownOutlined,
    CheckCircleOutlined,
    StarOutlined,
    StarFilled,
    CheckCircleFilled,
    CloseCircleOutlined,
  },
  setup() {
    const formRef = ref(null);
    const states = reactive({
      formModal: false,
      openKey: '1',
      collectList: [],
      subscribeList: [],
      selectId: '',
      form: {
        name: '',
        phone: '',
        email: '',
        company: '',
        position: '',
        date: '',
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur',
          },
          {
            max: 8,
            message: '长度不可超过8个字',
            trigger: 'blur',
          },
        ],
        phone: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur',
          },
          { pattern: mobileRegExp, message: '请输入正确的电话号码' },
        ],
      },
      videoList: [
        {
          url: 'https://app.issmart.com.cn/digitalHumansVideos/video1.mp4',
          label: '营销云介绍',
          id: '1',
          videoImgUrl: require(`../../assets/images/digitalHumans/videoImg1.jpg`),
        },
        {
          url: 'https://app.issmart.com.cn/digitalHumansVideos/video2.mp4',
          label: '艺术领域介绍',
          id: '2',
          videoImgUrl: require(`../../assets/images/digitalHumans/videoImg2.jpg`),
        },
        {
          url: 'https://app.issmart.com.cn/digitalHumansVideos/video3.mp4',
          label: '移动专线介绍',
          id: '3',
          videoImgUrl: require(`../../assets/images/digitalHumans/videoImg3.jpg`),
        },
      ],
    });
    const play = id => {
      const video = document.querySelectorAll('.iss-video');
      video.forEach(vid => {
        if (vid.id === id) {
          vid.play();
        } else {
          vid.pause();
        }
      });
    };
    const subscribe = element => {
      states.selectId = element;
      if (states.subscribeList.includes(element)) {
        const index = states.subscribeList.indexOf(element);
        states.subscribeList.splice(index, 1);
        message.success('已取消预约');
      } else {
        states.formModal = true;
      }
    };
    const collect = element => {
      if (states.collectList.includes(element)) {
        const index = states.collectList.indexOf(element);
        states.collectList.splice(index, 1);
        message.success('已取消收藏');
      } else {
        states.collectList.push(element);
        message.success('收藏成功');
      }
    };
    const handleOk = () => {
      formRef.value.validate().then(() => {
        states.subscribeList.push(states.selectId);
        states.formModal = false;
        message.success('预约成功');
      });
    };
    const close = () => {
      states.formModal = false;
      states.selectId = '';
    };
    const wxShare = obj => {
      const banner = obj.sharePicture;
      const title = obj.title;
      const remark = obj.describeDetail;
      const url = window.location.href;
      console.log(banner, title, remark, url);
      wechatApi
        .getWechatSign('', {
          url,
        })
        .then(data => {
          console.log(data);
          if (data) {
            wx.config({
              debug: false,
              ...data,
              jsApiList: [
                'onMenuShareTimeline',
                'onMenuShareAppMessage',
                'updateAppMessageShareData',
                'updateTimelineShareData',
                'showMenuItems',
                'hideMenuItems',
              ], // 必填,需要使用的JS接口列表
            });
            wx.ready(() => {
              // config信息验证成功后会执行ready方法,所有接口调用都必须在config接口获得结果之后
              // config 是一个客户端的异步操作,所以如果需要在页面加载时调用相关接口,则须把相关接口放在ready函数中调用来确保正确执行.对于用户触发是才调用的接口,则可以直接调用,不需要放在ready函数中
              wx.onMenuShareTimeline({
                title: title || '', // 分享标题
                desc: remark || '点击查看详情', // 分享描述
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: banner || '',
                success: res => {
                  // 设置成功
                  console.log(res);
                },
              });
              wx.onMenuShareAppMessage({
                title: title || '', // 分享标题
                desc: remark || '点击查看详情', // 分享描述
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: banner || '',
                success: res => {
                  console.log(res);
                },
              });
              wx.checkJsApi({
                // 判断当前客户端版本是否支持指定J||checkResult.updateTimelineShareData
                jsApiList: [
                  'updateAppMessageShareData',
                  'updateTimelineShareData',
                ],
                // 以键值对的形式返回，可用true，不可用false。如：{"checkResult":{"scanQRCode":true},"errMsg":"checkJsApi:ok"}
                success: res => {
                  console.log(res);
                  if (
                    res.checkResult.updateAppMessageShareData ||
                    res.checkResult.updateTimelineShareData
                  ) {
                    wx.updateAppMessageShareData({
                      title: title || '', // 分享标题
                      desc: remark || '点击查看详情', // 分享描述
                      link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                      imgUrl: banner || '',
                      success: () => {
                        // 设置成功
                      },
                    });
                    wx.updateTimelineShareData({
                      title: title || '', // 分享标题
                      desc: remark || '点击查看详情', // 分享描述
                      link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                      imgUrl: banner || '',
                      success: () => {},
                    });
                  } else {
                    message.info('抱歉，当前客户端版本不支持分享');
                  }
                },
              });
            });
          }
        });
    };
    onMounted(() => {
      wxShare({
        sharePicture:
          'https://ismartek-app-uat.issmart.com.cn/images/sharePic.jpg',
        title: '视界无限：探索数字人时代',
        describeDetail:
          '为了帮助您更好地体验和了解我们的展台，我们使用了数字人技术的前沿应用，请根据您的兴趣进行预约',
      });
    });
    return {
      formRef,
      ...toRefs(states),
      play,
      collect,
      subscribe,
      handleOk,
      close,
    };
  },
};
</script>

<style lang="less" scoped>
.digitalHumans {
  width: 100%;
  min-height: 100vh;
  padding: 30px 0;
  background: url('../../assets/images/digitalHumans/digitalHumansBg.jpg')
    no-repeat fixed center center / cover;
}
.flex-c {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-b {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-c-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.line {
  height: 2px;
  width: 84px;
  background-color: #fff;
}
.item-header {
  padding: 15px 25px;
  font-size: 16px;
  color: #fff;
  background: linear-gradient(104deg, #ff8e1f 0%, #ff6200 100%);
  border-radius: 9px 9px 0px 0px;
}
.borderRadius-9 {
  border-radius: 9px;
}
.iss-video {
  width: 100%;
}
.item-content {
  video {
    width: 100%;
    height: auto;
    vertical-align: middle;
  }
}
.item-footer {
  background-color: #fff;
  border-radius: 0px 0px 9px 9px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
}
.modal-content {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  padding: 0 24px;
}
.modal-header {
  position: relative;
  .close {
    position: absolute;
    right: 0;
    top: -60px;
  }
}
.footer-btn {
  width: 42%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: #fff;
  background: linear-gradient(104deg, #ff8e1f 0%, #ff6200 100%);
  border-radius: 9px;
}
</style>
